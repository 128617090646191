/*
	**FORMATTERS**
	The functions are used to convert incoming spreadsheet data into data suitable for the entities
*/

import moment from "moment"
import { ParticipationEvent } from "../../../entities"
import { hasOldValue } from "./eligibilityUploadHelpers"
import { EmploymentEvent } from "../../../entities/employment"
import { isSameAlphaNumStrings, isValidDate, parseExcelDate, today, toSearchString } from "../helper"
import { employmentEventConfigs } from "../../../entities/employment/EmploymentConfig"

export const INVALID_FIELD_STRING = 'Invalid field';

export function toYesNo(val) {
	if (val === 0) return 'n'
	else if (val === 1) return 'y'
	else if(!val) return ''
	
	val = String(val).toLowerCase()
	if (['missing', 'blank'].includes(val)) return ''
	if (['true', 'y', 'yes'].includes(val)) return 'y'
	return 'n'
}
export function toBlankIfUndefined(val) {
	return val ? val : ''
}
export function toParticipationEvent(val, code) {
	return val ? new ParticipationEvent({ets: moment(parseExcelDate(val)).valueOf(), code}) : null;
}
/**
 * Get the employment event
 * @param {*} val The date. Can be a number like `45292` or a string like `"17-Dec-23"`
 * @param {string} code The event code
 * @param {string | undefined} commentValue The event comment
 * @returns an `EmploymentEvent` or `null`
 */
export function toEmploymentEvent(val, code, commentValue) {
	const parsedDate = val ? parseExcelDate(val) : undefined;
	const momentDate = parsedDate ? moment(parsedDate) : (val && typeof val === 'string') ? moment(val) : undefined;
	return momentDate ? new EmploymentEvent({ets: momentDate.valueOf(), code, cmt: commentValue}) : null;
}

/**
 * Find the EmploymentEvent by message
 * @param {string | undefined} employmentEventDescription
 * @returns an `EmploymentEvent`'s code or `undefined`
 */
export function findEmploymentEventCode(employmentEventDescription) {
	if(!employmentEventDescription) {
		return undefined;
	}
	// The status string must match exactly with an event description in the employment history
	const eventCode = Object.values(EmploymentEvent.messages).find((v) => isSameAlphaNumStrings(v?.text, employmentEventDescription))?.key;
	
	 if(employmentEventConfigs[eventCode]?.useInMembersUploadStatusUpdate) {
		return eventCode;
	}

	return undefined;
}

export function toOptionValue(val, options) {
	if (!val) return '';
	val = toSearchString(val)
	const option = options.find(opt => toSearchString(opt.text) === val || opt.key === val)
	return option ? option.key : INVALID_FIELD_STRING;
}
export function toEnumValue(val, enumType) {
	return enumType.types[val] ? val : '';
}

export function getHistoricalDate(instance, loadedInstance, property) {
	if(isValidDate(loadedInstance[property + 'Date'])) {
		return loadedInstance[property + 'Date']; //Date from import
	} else if (isValidDate(loadedInstance.hiredDate)) {
		return loadedInstance.hiredDate; //Hire date from import
	} else if (instance.hiredDate) { 
		return instance.hiredDate; //Hire date in DB
	} else return today(); //Default to today
}

/**
 * Removes whitespaces on each end, return undefined if the value is 'missing' or 'blank'
 * @param {string} val Value to clean
 * @returns {string | undefined} Cleaned value
*/
export function toCleanString(val) {
	val = val?.trim();
	if (val === '') return undefined;
	return val;
}

export const renderValue = (key, value, instance) => {
	//Todo implement custom getters for each field if needed

	return hasOldValue(key, value, instance);
}