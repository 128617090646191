import { Employment, Person } from "../../../entities"
import WorkSchedule from "../../../entities/employment/EmploymentWorkSchedule"
import { parseExcelDate, phraseToPascal } from "../helper"
import { findEmploymentEventCode, toBlankIfUndefined, toEmploymentEvent, toEnumValue, toOptionValue, toParticipationEvent, toYesNo } from "./eligibilityUploadFormatters"
import { updateHistorical, updateOrAddMetEligDateEvent, updateOrAddNonEligEvent, updateOrAddPayrollStartDateEvent, updateSimple } from "./eligibilityUploadUpdaters"
import { updateOrAddHiredEvent } from "./eligibilityUploadUpdaters"

//Order of these fields are important, hire date should come first because it is referred to in Historical Fields
export const fields = [
	{entityName: 'Employment', name: 'employer.code', title: 'ER Code', alias:['code'], 
		format: (val) => val && String(val).toUpperCase(), key: true},
	{entityName: 'Person', name: 'sin', title: 'SIN', alias:['sin'], key: true},
	// isUpdateBlocked, hayes wants to update this manually
	{entityName: 'Employment', event: true, name: 'hiredDate', title: 'Hired Date', alias:['hiredate'], 
		format: (val, commentValue) => toEmploymentEvent(val, 'hrd', commentValue),
		update: updateOrAddHiredEvent,
		isUpdateBlocked: true,
	},
	{entityName: 'Employment', event: true, name: 'payrollStartDate', title: 'Payroll Start Date', alias:[], 
		format: (val, commentValue) => toEmploymentEvent(val, 'psd', commentValue), update: updateOrAddPayrollStartDateEvent},
	{entityName: 'Person', name: 'firstName', title: 'First Name', alias:[], 
		format: phraseToPascal, update: updateSimple},
	{entityName: 'Person', name: 'lastName', title: 'Last Name', alias:[], 
		format: phraseToPascal, update: updateSimple},
	{entityName: 'Person', name: 'gender', title: 'Sex (male/female)', alias:['gender', 'sex'], 
		format: (val) => toOptionValue(val, Person.definitions.gender.options), update: updateSimple},
	{entityName: 'Person', name: 'lng', title: 'Language (en/fr)', alias:['lng', 'language'], 
		format: (val) => toOptionValue(val, Person.definitions.lng.options), update: updateSimple},
	// isUpdateBlocked, hayes wants to update this manually
	{entityName: 'Person', name: 'dob', title: 'Date of Birth', alias:['dob', 'dateofbirth'], format: parseExcelDate, update: updateSimple, isUpdateBlocked: true},	
	{entityName: 'Employment', name: 'isN', title: 'Native (y/n)', alias:['isn', 'native'],
		format: toYesNo,
		update: updateHistorical},
	{entityName: 'Employment', name: 'isNDate', title: 'Native Date', alias:['isndate'], 
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'isTP', title: 'Tax Payer (y/n)', alias:['istp', 'taxpayer', 'paystax'],
		format: toYesNo,
		update: updateHistorical},
	{entityName: 'Employment', name: 'isTPDate', title: 'Tax Payer Date', alias:['istpdate', 'paystaxdate'], 
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'noEmp', title: 'Employee #', alias:['noemp', 'noemployee', 'employeeno', 'employeenumber'], 
		format: toBlankIfUndefined,
		update: updateSimple},
	{entityName: 'Employment', name: 'isCQ', title: 'CPP/QPP (y/n)', alias:['iscq', 'payscppqpp', 'cppqpp'],
		format: toYesNo,
		update: updateHistorical},
	{entityName: 'Employment', name: 'isCQDate', title: 'CPP/QPP Date', alias:['iscqdate', 'payscppqppdate'], 
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'weeklySch', setter: 'workSch', title: 'Work Schedule', alias:['worksch'],
		format: (val) => toEnumValue(val, WorkSchedule),
		update: updateHistorical},
	{entityName: 'Employment', name: 'workSchDate', title: 'Work Schedule Date', alias:['workschdate'],
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'employmentType', title: 'Employment Type (pt/st/cs)', alias:['employmenttype'],
		format: (val) => toOptionValue(val, Employment.definitions.employmentType.options),
		update: updateHistorical},
	{entityName: 'Employment', name: 'employmentTypeDate', title: 'Employment Type Date', alias:['emptypedate'],
		format: parseExcelDate,},
	{entityName: 'Participation', event: true, name: 'eligibilityDt', title: 'Eligibility Date', alias:['meteligibility', 'eligibilitydate'], //Order is important in this array to have metElig date appear before elig date
		format: (val) => toParticipationEvent(val, 'metEligDate'),
		update: updateOrAddMetEligDateEvent},
	// isUpdateBlocked, hayes wants to update this manually
	{entityName: 'Participation', event: true, name: 'joinDt', title: 'Join Date', alias:[ 'joindate'], 
		format: (val) => toParticipationEvent(val, 'metElig'), update: updateOrAddMetEligDateEvent, isUpdateBlocked: true},
	{entityName: 'Participation', event: true, name: 'nonEligibilityDt', title: 'Not Eligible Date', alias:['noneligibility', 'noneligibilitydate'], 
		format: (val) => toParticipationEvent(val, 'inegDes'), update: updateOrAddNonEligEvent},
	{entityName: 'Employment', event: false, name: 'eventStatus', title: 'Status', alias:['status', 'status31dec'], format: findEmploymentEventCode},
	{entityName: 'Employment', event: true, name: 'eventStatusDate', title: 'Status Date', alias:['statusdate'], format: parseExcelDate},
]

/**
 * Tabs in the eligibility upload summary
 */
export const tabs = {
	uploaded: {title: 'Uploaded Data', description: 'items loaded and parsed from the excel sheet. Incorrect formats will show as blank cells.'  , headersGroup: 'loaded', filter: () => true},
	errors: {title: 'Errors', description: 'critical errors preventing changes to be saved.', headersGroup: 'loaded', filter: (row) => row.message.code},
	tasksAfterSave: {title: 'Tasks', description: 'further actions that are required', headersGroup: 'loaded', filter: (row) => row.empTasks?.length},
	newPeople: {title: 'New People', description: 'new people that will be created once changes are saved.', headersGroup: 'new', filter: (row) => row.newPerson?.isTouched()},
	updatedPeople: {title: 'Updated People', description: 'existing people that will have values updated once changes are saved.', headersGroup: 'merged', filter: (row) =>  { return row.mergedPerson?.isTouched() }},
	newEmployments: {title: 'New Employments', description: 'new employments that will be created once the changes are saved.', headersGroup: 'new', filter: (row) => row.newEmployment?.isTouched()}, 
	updatedEmployments: {title: 'Updated Employments', description: 'existing employments that will have values updated once changes are saved.', headersGroup: 'merged', filter: (row) => row.mergedEmployment?.isTouched()},
	newParticipation: {title: 'New Participations', description: 'new participations that will be created once the changes are saved.', headersGroup: 'new', filter: (row) => row.newParticipation?.isTouched()}, 
	updatedParticipation: {title: 'Updated Participations',description: 'existing participations that will have values updated once changes are saved.', headersGroup: 'merged', filter: (row) => row.mergedParticipation?.isTouched()},
}