import { employmentEventConfigs } from "../../../entities/employment/EmploymentConfig";
import { Ref } from "../../infra";
import { isValidDate, moment, toEpochTs } from "../helper";
import { toEmploymentEvent } from "./eligibilityUploadFormatters";
import { getHistoricalDate } from "./eligibilityUploadFormatters";
import { assignComment } from "./eligibilityUploadHelpers";

/*
	**UPDATERS**
	These functions are used to update drafts and detect updates or give errors if there are any. 
*/
export class UpdateResponse {
	constructor(updated, error) {
		this.updated = updated ?? false;
		this.error = error;
    }
}

export function updateSimple(instance, loadedInstance, property) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	if (newValue && newValue !== '' && instance[property] !== newValue) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;
		res.updated = true;
	}
	return res;
}

export function updateHistorical(instance, loadedInstance, property) {

	const res = new UpdateResponse();
	const newValObj = loadedInstance[property];
	let newValString;

	if (newValObj instanceof Ref) { 
		//might be enum so we need to access by key
		newValString = newValObj.key ?? '';
	} else {
		newValString = newValObj?.toString();
	}

	const date = getHistoricalDate(instance, loadedInstance, property);
	let val = instance[property + 'History'].getAt(toEpochTs(date))?.value;
	if(Object.hasOwn(val, 'key')) val =  val.key;

	
	if (newValString !== '' && val?.toString() !== newValString) {
		const histItem = instance[property + 'History'].create();
		histItem.value = newValObj;
		histItem.ets = toEpochTs(date)
		instance[property + 'History'].addNewHistoricalItem(histItem);
		res.updated = true;
		res.oldVal = val ?? '';
		res.output = {property: property, oldVal: val ?? '', newVal: newValString, date: date}
	}

	return res;
}

/**
 * Update or add a "PayrollStartDateEvent" event (code: `psd`) in the Employment
 * @param {Employment} instance Employment instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `payrollStartDate`
 * @returns {UpdateResponse}
 */
export function updateOrAddPayrollStartDateEvent(instance, loadedInstance, property) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	if (isValidDate(newValue) && newValue !== instance[property]) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;

		const payrollStartDateEvent = loadedInstance.getPayrollStartDateEvent() ?? {code: 'psd', effDt: newValue};
		if(instance.getPayrollStartDateEvent()) {
			instance.updateEvent(instance.getPayrollStartDateEvent(), assignComment(payrollStartDateEvent, property, loadedInstance));
		} else {
			instance.addEvent(assignComment(payrollStartDateEvent, property, loadedInstance));
		}
		res.updated = true;
	}
	return res;
}

/**
 * Update or add a "met eligibility date" event (code: `metEligDate`) in the Participation
 * @param {Participation} instance Participation instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `eligibilityDt`
 * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference.
 * The `employmentInstance` is used in the participation event.
 * @returns {UpdateResponse}
 */
export function updateOrAddMetEligDateEvent(instance, loadedInstance, property, optionalInstances) {
	const res = new UpdateResponse();
	const oldEvent = instance.events.find(ev => ev.code === 'metEligDate' && (
		// if we passed an employment reference, use it to find the existing event
		(optionalInstances?.employmentInstance && ev.pointers?.length &&
		ev.pointers?.find(p => p.name === 'employer' && (p.instance?.id === optionalInstances?.employmentInstance?.employer?.id)) 
		) 
		// because toParticipationEvent() adds the event without a pointer
		|| !ev.pointers?.find(p => p.name === 'employer')
	));
	const newEvent = loadedInstance.events?.find(ev => ev.code === 'metEligDate');
	if (newEvent && (newEvent.effDt !== oldEvent?.effDt || (oldEvent && oldEvent.effDt === newEvent.effDt && !oldEvent.pointers?.length))) {
		res.output = {property: property, oldVal: oldEvent?.effDt ?? '' }

		const event = newEvent;
		if(oldEvent) {
			instance.updateEvent(oldEvent, assignComment(event, property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance });
		} else {
			instance.addEvent(assignComment(event, property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance }); 
		}
		res.updated = true;
	}

	return res;
}

/**
 * Update or add a "Not Eligible - As designated by Employer" event (code: `inegDes`) in the Participation
 * @param {Participation} instance Participation instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `nonEligibilityDt`
 * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference.
 * The `employmentInstance` is used in the participation event.
 * @returns {UpdateResponse}
 */
export function updateOrAddNonEligEvent(instance, loadedInstance, property, optionalInstances) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	const newEvent = loadedInstance.events?.find(ev => ev.code === 'inegDes');
	const oldEvent = instance.events.find(ev => ev.code === 'inegDes' && (
		// if we passed an employment reference, use it to find the existing event
		(optionalInstances?.employmentInstance && ev.pointers?.length &&
		ev.pointers?.find(p => p.name === 'employer' && p.instance?.id === optionalInstances?.employmentInstance?.employer?.id) 
		)
		// because toParticipationEvent() adds the event without a pointer
		|| !ev.pointers?.find(p => p.name === 'employer'))
		&& (newEvent?.effMoment && ev?.effMoment ? ev.effMoment.year() === newEvent.effMoment.year() : false)
	);
	if (newEvent && (newEvent.effDt !== oldEvent?.effDt || (oldEvent && oldEvent.effDt === newEvent.effDt && !oldEvent.pointers?.length))) {
		res.output = {property: property, oldVal: oldEvent?.effDt ?? '' }
		
		const event = newEvent;
		if(oldEvent) {
			const options = {openEmployment: optionalInstances?.employmentInstance, employment: optionalInstances?.employmentInstance };
			instance.updateEvent(oldEvent, assignComment(event, property, loadedInstance), options);
		} else {
			const options = {openEmployment: optionalInstances?.employmentInstance };
			instance.addEvent(assignComment(event, property, loadedInstance), options);
		}
		res.updated = true;
	}
	if (isValidDate(newValue) && newValue !== instance[property]) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;

		res.updated = true;
	}
	return res;
}

/**
 * Processes both `eventStatus` and `eventStatusDate` in the Employment
 * @param {Employment} employmentInstance The Employment instance
 * @param {*} loadedEmploymentInstance The loaded row
 * @param {*} optionalInstances The optional instances to use as reference.
 * @returns {UpdateResponse}
 */
export function updateOtherEmploymentEventFromStatus(employmentInstance, loadedEmploymentInstance, optionalInstances) {
	const res = new UpdateResponse();

	const eventStatusDate = loadedEmploymentInstance['eventStatusDate'];

	const instanceEventAt = employmentInstance.eventStatuses?.getAt?.(moment(eventStatusDate).valueOf());
	const event = loadedEmploymentInstance.events.all.filter(ev => employmentEventConfigs[ev.code]?.useInMembersUploadStatusUpdate)[0];
	const needUpdate = event?.status?.key && instanceEventAt?.status?.key !== event.status.key;

	if(needUpdate) {
		// the loaded event is not the same status as the event from the instance
		const eventWithComment = assignComment(event, 'eventStatusDate', loadedEmploymentInstance);
		employmentInstance.addEvent(eventWithComment);
		res.updated = true;
	} else {
		// nothing to do, status is already the same
	}

	return res;
}

/**
  * Update or add a "met eligibility" ("joined") event (code: `metElig`) in the Participation
  * @param {Participation} instance Participation instance
  * @param {*} loadedInstance The loaded row
  * @param {string} property Property name, for example `joinDt`
  * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference. The `employmentInstance` is used in the participation event
  * @returns {UpdateResponse}
	  */
export function updateOrAddMetEligEvent(instance, loadedInstance, property, optionalInstances) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	const oldEvent = instance.events.all.find(ev => Boolean(ev.config.isEnrollEvent));
	const newEvent = loadedInstance.events?.find(ev => ev.code === 'metElig');
	if (newEvent && newEvent.effDt !== oldEvent?.effDt) {
		res.output = {property: property, oldVal: oldEvent?.effDt ?? '' }
		
		const event = newEvent;
		if(oldEvent) {
			instance.updateEvent(oldEvent, assignComment(toEmploymentEvent(event.effDt, oldEvent.code), property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance, employment: optionalInstances?.employmentInstance });
		} else {
			instance.addEvent(assignComment(event, property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance });
		}
		res.updated = true;
	}
	if (isValidDate(newValue) && newValue !== instance[property]) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;

		res.updated = true;
	}
	return res;
}

/**
* Update or add a "HiredEvent" event (code: `hrd`) in the Employment
* @param {Employment} instance Employment instance
	* @param {*} loadedInstance The loaded row
* @param {string} property Property name, for example `hiredDate`
* @returns {UpdateResponse}
*/
export function updateOrAddHiredEvent(instance, loadedInstance, property) {
   const res = new UpdateResponse();
   const newValue = loadedInstance[property];
   if (isValidDate(newValue)) {
	   res.output = {property: property, oldVal: instance[property] }
	   instance[property] = newValue;
	   const hiredEvent = loadedInstance.getHiredEvent() ?? {code: 'hrd', effDt: newValue};
	   if(instance.getHiredEvent()) {
		   instance.updateEvent(instance.getHiredEvent(), assignComment(hiredEvent, property, loadedInstance))
	   } else {
		   instance.addEvent(assignComment(hiredEvent, property, loadedInstance));
	   }
	   res.updated = true;
   }
   return res;
}